import { SUBSCRIPTION_STATUS, TRIAL_CREDIT_ENABLED } from '../constants';
import {
  PackageType,
  Subscription,
  SubscriptionPackage,
  TransformedSubscriptionInfo,
} from '../types';

export const filterCustomSubscriptionPackage = ({
  subsPackages,
}: {
  subsPackages: SubscriptionPackage[];
}) => {
  return subsPackages?.filter((subsPackage) => subsPackage.type !== 'custom');
};

export const sortSubscriptionPackageByPrice = ({
  subsPackages,
}: {
  subsPackages: SubscriptionPackage[];
}) => {
  return subsPackages?.sort(
    (a, b) => (a.newPricing?.[0]?.price || 0) - (b.newPricing?.[0]?.price || 0)
  );
};

export const getDefaultSubscriptionPackages = ({
  subsPackages,
  packageType,
}: {
  subsPackages: SubscriptionPackage[];
  packageType: keyof PackageType;
}) => {
  return [...subsPackages]
    ?.filter((subsPackage) => subsPackage.target === 'all')
    ?.slice(0, packageType === 'flexible' ? 3 : 2);
};

export const getCustomSubscriptionPackages = ({
  subsPackages,
}: {
  subsPackages: SubscriptionPackage[];
}) => {
  return [...subsPackages]
    ?.filter((subsPackage) => subsPackage.target !== 'all')
    ?.slice(0, 1);
};

export const transformSubscriptionPackages = ({
  customSubsPackages,
  defaultSubsPackages,
  packageType,
}: {
  customSubsPackages: SubscriptionPackage[];
  defaultSubsPackages: SubscriptionPackage[];
  packageType: keyof PackageType;
}) => {
  const selectedSubsPackages =
    customSubsPackages?.length > 0
      ? [
          ...customSubsPackages,
          ...(packageType === 'flexible'
            ? defaultSubsPackages?.length > 0
              ? defaultSubsPackages.slice(-2)
              : []
            : [defaultSubsPackages[defaultSubsPackages.length - 1]]),
        ]
      : [...defaultSubsPackages];

  let highlighted = false;

  const subsPackages = selectedSubsPackages?.map((subsPackage, index) => {
    let tempPackage = { ...subsPackage };
    const packageHighlighted =
      (subsPackage?.target !== 'all' ||
        index + 1 === selectedSubsPackages.length) &&
      !highlighted;
    if (packageHighlighted) {
      tempPackage.highlighted = true;
      highlighted = true;
    }

    return tempPackage;
  });

  return subsPackages;
};

/**
 * transformSubscriptionInfo
 * @desc - Transform subscription data into computed subscription info
 */
export const transformSubscriptionInfo = ({
  subscription,
}: {
  subscription?: Partial<
    Pick<
      Subscription,
      | 'jobPostLimit'
      | 'status'
      | 'jobPostActiveCount'
      | 'expiredAt'
      | 'companyRetail'
    >
  >;
}): TransformedSubscriptionInfo => {
  const initialComputedSubscriptionInfo = {
    subscriptionStatusIsTrial: false,
    subscriptionStatusIsSubscribe: false,
    subscriptionStatusIsFlexible: false,
    expired: false,
    availableJobSlot: 0,
    availableCredit: 0,
    availableTrialCredit: 0,
    availableJobCredit: 0,
    activeUsedTrialCredit: 0,
    activeUsedJobCredit: 0,
    activeTrialCredit: 0,
    activeJobCredit: 0,
    usedTrialCredit: 0,
    usedJobCredit: 0,
    hasJobSlot: false,
    hasCredit: false,
  };

  if (!subscription) return initialComputedSubscriptionInfo;

  const {
    status,
    expiredAt,
    jobPostActiveCount = 0,
    jobPostLimit = 0,
    companyRetail,
  } = subscription;

  const {
    activeCount: companyRetailActiveCount = 0,
    activeTrialCreditsCount: companyRetailActiveTrialCreditsCount = 0,
    activeUsedCount: companyRetailActiveUsedCount = 0,
    availableCount: companyRetailAvailableCount = 0,
    availableTrialCreditsCount: companyRetailAvailableTrialCreditsCount = 0,
    usedCount: companyRetailUsedCount = 0,
    usedTrialCreditsCount: companyRetailUsedTrialCreditsCount = 0,
    activeUsedTrialCreditsCount: companyRetailActiveUsedTrialCreditsCount = 0,
  } = companyRetail || {};

  const subscriptionStatusIsTrial = status === SUBSCRIPTION_STATUS['TRIAL'];
  const subscriptionStatusIsSubscribe =
    status === SUBSCRIPTION_STATUS['SUBSCRIBE'];
  const subscriptionStatusIsFlexible =
    status === SUBSCRIPTION_STATUS['FLEXIBLE'];

  const expired = expiredAt ? new Date() >= new Date(expiredAt) : false;

  const availableJobSlot = jobPostLimit - jobPostActiveCount;

  const availableTrialCredit =
    (TRIAL_CREDIT_ENABLED
      ? companyRetailAvailableTrialCreditsCount
      : companyRetailAvailableCount) ?? 0;
  const availableJobCredit = TRIAL_CREDIT_ENABLED
    ? (companyRetailAvailableCount ?? 0) - availableTrialCredit
    : companyRetailAvailableCount;

  const availableCredit =
    (subscriptionStatusIsTrial && TRIAL_CREDIT_ENABLED
      ? availableTrialCredit
      : availableJobCredit) ?? 0;

  const hasJobSlot = availableJobSlot > 0;
  const hasCredit = availableCredit > 0;

  const activeUsedTrialCredit = TRIAL_CREDIT_ENABLED
    ? companyRetailActiveUsedTrialCreditsCount
    : companyRetailActiveUsedCount;
  const activeUsedJobCredit = TRIAL_CREDIT_ENABLED
    ? companyRetailActiveUsedCount - activeUsedTrialCredit
    : companyRetailActiveUsedCount;

  const activeTrialCredit = TRIAL_CREDIT_ENABLED
    ? companyRetailActiveTrialCreditsCount
    : companyRetailActiveCount;
  const activeJobCredit = TRIAL_CREDIT_ENABLED
    ? companyRetailActiveCount - activeTrialCredit
    : companyRetailActiveCount;

  const usedTrialCredit = TRIAL_CREDIT_ENABLED
    ? companyRetailUsedTrialCreditsCount
    : companyRetailUsedCount;
  const usedJobCredit = TRIAL_CREDIT_ENABLED
    ? companyRetailUsedCount - usedTrialCredit
    : companyRetailUsedCount;

  return {
    subscriptionStatusIsTrial, // Company susbcription status is trial
    subscriptionStatusIsSubscribe, // Company subscription status is subscribe
    subscriptionStatusIsFlexible, // Company subscription status is flexible
    expired, // Company subscription is expired (expiredAt < now, only valid for subscribe & trial status)
    availableJobSlot, // Available job slot that can be used to post/reactivate a job
    availableCredit, // Available credit that can be used to post/reactivate a job. Considering whether company subs status is trial or subscribe. (Use this field unless you have specific needs to use availableTrialCredit/availableJobCredit field)
    availableTrialCredit, // Available credit that can be used to post/reactivate a job only in trial period
    availableJobCredit, // Available credit that can be used to post/reactivate a job only in subscription/flexible period
    activeUsedTrialCredit, // Active used credit in trial period
    activeUsedJobCredit, // Active used credit in subscription/flexible period
    activeTrialCredit, // Active credit in trial period
    activeJobCredit, // Active credit in subscription/flexible period
    usedTrialCredit, // Used credit in trial period
    usedJobCredit, // Used credit in subscription/flexible period
    hasJobSlot, // Company has available job slot
    hasCredit, // Company has available credit. Considering whether company subs status is trial or subscribe.
  };
};
