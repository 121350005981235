import { AxiosRequestConfig } from 'axios';

import { BackendRes } from '@/common/types';
import { apiInstance } from '@/modules/core/axios/configs';

import { SubscriptionPackage } from '../types';

const SubscriptionService = {
  _getPackageById: (instance, packageId) => {
    const key = `/v1/job-portal/subscription/package/${packageId}`;

    const fetcher = async () => {
      try {
        const response = await instance.get(key);
        return response.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

    return { key, fetcher };
  },

  generateInvoice: (instance, { transactionId, fileName }) => {
    return instance.get(
      `/v1/doc/job-portal/${transactionId}/invoice-${fileName}.pdf`,
      {
        responseType: 'arraybuffer',
      }
    );
  },

  getCompanySubscription: ({
    axiosConfig,
  }: { axiosConfig?: AxiosRequestConfig } = {}) => {
    const { params } = axiosConfig || {};
    const companyId = params.companyId;
    delete params.companyId;

    return apiInstance.get(
      `/v1/job-portal/company/${companyId}/subscription`,
      axiosConfig
    );
  },

  getSubcriptionPackageList: ({
    axiosConfig,
  }: { axiosConfig?: AxiosRequestConfig } = {}) =>
    apiInstance.get<BackendRes<{ result: { docs: SubscriptionPackage[] } }>>(
      '/v1/job-portal/subscription/package',
      axiosConfig
    ),

  getSubcriptionPackage: ({
    axiosConfig,
  }: { axiosConfig?: AxiosRequestConfig } = {}) => {
    const { params } = axiosConfig || {};
    const packageId = params.id;
    delete params.id;
    return apiInstance.get<BackendRes<{ result: SubscriptionPackage }>>(
      `/v1/job-portal/subscription/package/${packageId}`,
      axiosConfig
    );
  },

  getTransactionDetail: (instance, params) => {
    return instance.get(`/v1/transactions/detail`, {
      params: {
        ...params,
      },
    });
  },

  getTransactionHistory: (instance, page = 1, limit = 5) => {
    const params = new URLSearchParams({
      page,
      limit,
      activityType: 'purchaseDeallSubscription',
    } as unknown as Record<string, string>);

    const key = `/v1/transactions/history?${params}`;

    const fetcher = async () => {
      try {
        const response = await instance.get(key);

        return response.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

    return { key, fetcher };
  },

  purchaseSubscription: (instance, data) => {
    return instance.post('/v1/job-portal/subscription/purchase', {
      ...data,
    });
  },

  requestTrialMode: (instance) => {
    return instance.post('/v1/job-portal/company/trial');
  },
};

export default SubscriptionService;
