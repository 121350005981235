import clsx from 'clsx';
import moment from 'moment';

import { InvoicePDFButton } from '@/modules/settings/components/MySubscription/GenerateInvoicePDF';

import { SubscriptionPackageRecordType } from '../types';

const DAYS_PER_MONTH = 30;

export const TRIAL_CREDIT_ENABLED =
  process.env.NEXT_PUBLIC_TRIAL_CREDIT_ENABLED === 'true';

export const FEATURE_TYPE = {
  default: 'default',
  flexible: 'flexible',
  subscription: 'subscription',
} as const;

export const PACKAGE_TYPE = {
  flexible: 'flexible',
  subscription: 'subscription',
} as const;

export const SUBSCRUPTION_FEATURE_ATTRIBUTE = {
  jobSlot: 'jobSlot',
  talentSearch: 'talentSearch',
  maxCandidate: 'maxCandidate',
  teamMember: 'teamMember',
} as const;

export const SUBSCRIPTION_TYPES = [
  {
    key: 'flexible',
    name: 'Flexible Job Credits',
    description: 'For teams with lean hiring needs',
  },
  {
    key: 'subscription',
    name: 'Subscription',
    description: 'For expanding & scaling businesses',
  },
];

export const DEFAULT_FEATURES = [
  {
    key: 1,
    type: FEATURE_TYPE.default,
    text: (
      <p>
        <strong>AI-powered</strong> relevant technology
      </p>
    ),
  },
  {
    key: 2,
    type: FEATURE_TYPE.default,
    text: (
      <p>
        <strong>Integration</strong> to LinkedIn, Tech in Asia, and Google Jobs
      </p>
    ),
  },
  {
    key: 3,
    type: FEATURE_TYPE.default,
    text: (
      <p>
        <strong>All ATS</strong> features
      </p>
    ),
  },
];

export const FLEXIBLE_FEATURES: Omit<
  SubscriptionPackageRecordType,
  'packages'
>[] = [
  ...DEFAULT_FEATURES,
  {
    key: 1,
    type: FEATURE_TYPE.flexible,
    text: (
      <p>
        <strong>Unlimited</strong> team members
      </p>
    ),
  },
  {
    key: 2,
    type: FEATURE_TYPE.flexible,
    text: <p>Job open for 30 days</p>,
  },
  {
    key: 3,
    type: FEATURE_TYPE.flexible,
    text: <p>No expiration date</p>,
  },
];

export const SUBSCRIPTION_FEATURES: Omit<
  SubscriptionPackageRecordType,
  'packages'
>[] = [
  ...DEFAULT_FEATURES,
  {
    key: 1,
    type: FEATURE_TYPE.subscription,
    attribute: SUBSCRUPTION_FEATURE_ATTRIBUTE.jobSlot,
    text: (
      <p>
        <strong>Active</strong> Job Slot
      </p>
    ),
  },
  {
    key: 2,
    type: FEATURE_TYPE.subscription,
    attribute: SUBSCRUPTION_FEATURE_ATTRIBUTE.talentSearch,
    text: (
      <p>
        <strong>Talent</strong> Search
      </p>
    ),
  },
  {
    key: 3,
    type: FEATURE_TYPE.subscription,
    attribute: SUBSCRUPTION_FEATURE_ATTRIBUTE.maxCandidate,
    text: (
      <p>
        <strong>Max</strong> Candidates
      </p>
    ),
  },
  {
    key: 4,
    type: FEATURE_TYPE.subscription,
    attribute: SUBSCRUPTION_FEATURE_ATTRIBUTE.teamMember,
    text: (
      <p>
        <strong>Team</strong> Members
      </p>
    ),
  },
];

export const TRIAL_INFORMATION = {
  curatedTopTalentsCount: '93K+',
  durationInDays: '10',
  jobQuota: '1',
  memberQuota: 'unlimited',
};

export const BILLING_INVOICE_COLUMN = () => [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    sortDirections: ['descend'],
    width: 220,
    render: (data) => {
      return (
        <p className="text-sm font-normal">
          <span className="whitespace-nowrap">
            {moment(data).format('D MMMM YYYY')}
          </span>
        </p>
      );
    },
  },
  {
    title: 'Package Name',
    dataIndex: ['purchaseJobPortalSubsDetail', 'subscriptionPackage'],
    width: 220,
    render: (data) => {
      return <p className="text-sm font-normal">{data?.name || '-'}</p>;
    },
  },
  {
    title: 'Period',
    dataIndex: ['purchaseJobPortalSubsDetail', 'priceOption'],
    width: 300,
    render: (data, row) => {
      const pricingPackage = row.purchaseJobPortalSubsDetail?.pricingPackage;
      if (pricingPackage) {
        const subscriptionPackage =
          row.purchaseJobPortalSubsDetail?.subscriptionPackage;

        if (subscriptionPackage?.recurring) {
          return (
            <span className={clsx('text-sm font-normal capitalize')}>
              {pricingPackage?.label}
            </span>
          );
        }

        return (
          <span className={clsx('text-sm font-normal')}>
            {subscriptionPackage?.availableJobRetail
              ? `${subscriptionPackage?.availableJobRetail} Job Credit`
              : ''}
          </span>
        );
      }

      const subscriptionIsCustom = data === 'custom';
      const subscriptionDuration =
        `${Math.round(
          row.purchaseJobPortalSubsDetail?.subscriptionPackage?.durationDay /
            DAYS_PER_MONTH
        )} Month(s)` || 'Custom';
      return (
        <span
          className={clsx(
            'text-sm font-normal',
            !subscriptionIsCustom && 'capitalize'
          )}
        >
          {subscriptionIsCustom ? subscriptionDuration : data}
        </span>
      );
    },
  },
  {
    title: 'Billing Amount',
    dataIndex: 'amount',
    sortDirections: ['descend'],
    width: 230,
    render: (data) => {
      return (
        <span className="text-sm font-normal">
          {Math.abs(data).toLocaleString('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </span>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 180,
    render: (data) => {
      return (
        <div>
          <span
            style={{
              background:
                data === 'paid' ? 'rgba(0, 138, 136, 0.1)' : '#FFF2F2',
            }}
            className={`rounded-[16px] px-4 py-1 text-sm font-bold ${
              data === 'paid' ? 'text-aquamarine-700' : 'text-danger-500'
            } capitalize`}
          >
            {data}
          </span>
        </div>
      );
    },
  },
  {
    dataIndex: 'action',
    render: (_, row) => {
      return (
        <>
          {row?.status === 'paid' && (
            <InvoicePDFButton transactionId={row._id} refId={row.referenceId} />
          )}
        </>
      );
    },
  },
];

export const ERROR_SUBSCRIPTION = {
  JOB_QUOTA_REACHED: 'JOB_QUOTA_REACHED',
};

export const SUBSCRIPTION_STATUS = {
  TRIAL: 'trial',
  SUBSCRIBE: 'subscribe',
  FLEXIBLE: 'flexible',
};
