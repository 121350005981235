import { Button, message } from 'antd';
import { useState } from 'react';

import { useStoreApi } from '@/common/contexts/useApi';
import { getCharacterFromLast, parseErrorMessage } from '@/common/utils';
import SubscriptionService from '@/modules/subscription/services/SubscriptionService';

export const InvoicePDFButton = ({ transactionId, refId }) => {
  const { axios } = useStoreApi();
  const [loading, setLoading] = useState(false);

  const getInvoiceData = async (_id, referenceId) => {
    setLoading(true);
    try {
      const response = await SubscriptionService.generateInvoice(axios, {
        transactionId: _id,
        fileName: `INV-Deall-EDPSP-22${getCharacterFromLast(referenceId, 2)}`,
      });

      const pdfUrl = `${response?.request?.responseURL}`;
      const newWin = window.open(pdfUrl, '_blank');

      if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
        message.error('Popups is blocked, please turn on popup to see Invoice');
      }
    } catch (error) {
      setLoading(false);
      const errorMessage = parseErrorMessage(error);
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      className="font-medium text-violet-500"
      disabled={loading}
      onClick={() => getInvoiceData(transactionId, refId)}
      type="link"
    >
      View Invoice
    </Button>
  );
};
